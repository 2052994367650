import * as React from "react"
import Layout from "../components/Layout"

import AppSeo from "../components/AppSeo"
import Blog from "../components/Blog"

const blog = () => (
    <Layout>
      <AppSeo title="Blog" />
      <Blog />

    </Layout>
  )
  
  export default blog