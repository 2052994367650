import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import { Button } from "./Button"

export default function Blog () {

  const data = useStaticQuery(graphql`
  query BLOGDESC {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {published: {eq: true}}}
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          published
          title
          description
          thumbalt
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
        fields {
          slug
        }
        id
      }
    }
  }
  `)

  return (
    <BlogSection>
      <BlogHeader>Blog</BlogHeader>
      {data.allMdx.nodes.map(({fields, frontmatter, id}) => {
        //console.log(frontmatter.thumbnail);
        //console.log(id);
        const image = getImage(frontmatter.thumbnail)
        return(
          <BlogItem key={id}>
            <BlogDate>{frontmatter.date}</BlogDate>
            <BlogImage image={image} alt={frontmatter.thumbalt}/>
            <BlogInfo>
              <BlogTitle>{frontmatter.title}</BlogTitle>
              <BlogDesc>{frontmatter.description}</BlogDesc>
              <BlogButton to={fields.slug}>Read more {">"}</BlogButton>
            </BlogInfo>
          </BlogItem>
        )
    })}
    </BlogSection>
  )
}

const BlogSection = styled.section`
    margin: 3rem 9.375rem 1.5rem;
    padding: 0 calc((100vw - 1440px) / 2);
    color: #2A2C2B;

    @media screen and (max-width: 1020px){
      margin: 2rem 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 2rem 1rem;
    }
`

const BlogHeader = styled.h1`

    text-transform: uppercase;
`

const BlogItem = styled.div`
    background-color: #E5E5E5;
    margin: 2rem 0 2rem 5rem;
    padding: 1rem 2rem 1rem 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: 2rem;
    //grid-template-rows: auto 1fr auto;  /* key rule */
    grid-template-areas:  "image image" 
                          "title title"
                          "date   date"  
                          "info info";

    @media screen and (max-width: 1020px){
      grid-template-columns: 1fr 6fr;
      grid-column-gap: 2rem;
    }

    @media screen and (max-width: 768px){
      grid-template-columns: 3fr 1fr;
      grid-column-gap: 1rem;
    }
    
`

const BlogImage = styled(GatsbyImage)`
    display: block;
    //width: fit-content;
    margin-left: -6rem;

    @media screen and (max-width: 768px){
      //margin-left: 0;
      //order: -1;
      grid-area: image;
    }
`

const BlogInfo = styled.div`

    @media screen and (max-width: 768px){
      //margin-left: 0;
      //order: -1;
      grid-area: info;
    }
`

const BlogTitle = styled.h2`
    margin-bottom: 1rem;
    
    @media screen and (max-width: 768px){
      margin-top: 1rem;
      font-size: 1.4rem;
      grid-area: title;
    }
`

const BlogDesc = styled.p`
    
`

const BlogDate = styled.p`
    grid-column-start: 2;
    margin: .4rem 0;
    font-size: .9rem;

    @media screen and (max-width: 768px){
      grid-area: date;
    }
`

const BlogButton = styled(Button)`  
    margin-top: 4rem;
    display: block;

    @media screen and (max-width: 768px){
      margin-top: 1rem;
    }
`